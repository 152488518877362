<template>
  <div>
    <Card>
      <template #title>{{ $t('label.tickets') }}</template>
      <template #body>
        <app-apex-chart :categories="categories" :series="series" @markerClicked="filter"/>
      </template>
    </Card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {getTicketStatusColor} from '@/helpers/statuses-helper';
import Card from '@/components/AppCard';
import AppApexChart from '@/components/charts/AppApexChart';

export default {
  components: {
    Card,
    AppApexChart
  },
  props: {
    analyticsData: {type: Array, default: () => []}
  },
  data: () => ({
    categories: [],
    series: [],
    statuses: []
  }),
  beforeMount() {
    this.$store.dispatch('ticketsStore/GET_STATUSES');
  },
  computed: {
    ...mapGetters({
      originalStatuses: 'ticketsStore/STATUSES'
    })
  },
  watch: {
    analyticsData(data) {
      this.series = this.statuses
          .map(status => {
            if (!data.length) return {data: []};
            return {
              code: status.name,
              name: status.label,
              color: this.getTicketStatusColor(status.name),
              data: data
                  .filter(el => el.hasOwnProperty('tickets') && el.tickets[status.name].value !== 0)
                  .map(el => {
                    if (!this.categories.includes(el.complex.name)) {
                      this.categories.push(el.complex.name);
                    }
                    return parseInt(el.tickets[status.name].value);
                  })
            }
          })
          .filter(el => el.data.length);
    },
    originalStatuses(data) {
      this.statuses = [{id: 0, label: this.$t('label.updated_today'), name: 'updated_today'}].concat(data);
    }
  },
  methods: {
    getTicketStatusColor,
    filter(config) {
      let currentElement = this.analyticsData.find(el => el.complex.name === this.categories[config.dataPointIndex]);
      let complexId = currentElement.complex.id;
      let statusId = currentElement.tickets[this.series[config.seriesIndex].code].id;

      this.$router.push({name: 'ticketsList', query: {complex_id: complexId, status_id: statusId}})
    }
  }
}
</script>