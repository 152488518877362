<template>
  <div>
    <!--begin::Dashboard-->
    <b-row class="mb-7">
      <b-col md="6">
        <tickets-analytics :analytics-data="analyticsData"/>
      </b-col>
      <b-col md="6">
        <tasks-analytics :analytics-data="analyticsData"/>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-8 mb-7">
<!--        <ListWidget9></ListWidget9>-->
        <elevator-certificates-list
          :block-id="0"
          :elevator-id="0"
          :filters="{expired: certificatesFilterExpiredDate}"
          expiring-certificates
        />
      </div>

      <div class="col-xxl-4">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xl-4">
        <ListWidget11></ListWidget11>
      </div>
      <div class="col-xl-4">
        <ListWidget12></ListWidget12>
      </div>
      <div class="col-xl-4">
        <ListWidget13></ListWidget13>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 order-1 order-xxl-2">
        <ListWidget14></ListWidget14>
      </div>
      <div class="col-xxl-6 order-1 order-xxl-2">
        <ListWidget15></ListWidget15>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import AdvancedTableWidget2 from '@/core/content/widgets/advance-table/Widget2.vue';
import ElevatorCertificatesList from '@/views/blocks/components/ElevatorCertificatesList';
import MixedWidget1 from '@/core/content/widgets/mixed/Widget1.vue';
import ListWidget1 from '@/core/content/widgets/list/Widget1.vue';
// import ListWidget9 from '@/core/content/widgets/list/Widget9.vue';
import ListWidget11 from '@/core/content/widgets/list/Widget11.vue';
import ListWidget12 from '@/core/content/widgets/list/Widget12.vue';
import ListWidget13 from '@/core/content/widgets/list/Widget13.vue';
import ListWidget14 from '@/core/content/widgets/list/Widget14.vue';
import ListWidget15 from '@/core/content/widgets/list/Widget15.vue';
import TicketsAnalytics from './components/TicketsAnalytics';
import TasksAnalytics from './components/TasksAnalytics';

export default {
  name: 'dashboard',
  components: {
    AdvancedTableWidget2,
    ElevatorCertificatesList,
    MixedWidget1,
    ListWidget1,
    // ListWidget9,
    ListWidget11,
    ListWidget12,
    ListWidget13,
    ListWidget14,
    ListWidget15,
    TicketsAnalytics,
    TasksAnalytics,
  },
  data: () => ({
    certificatesFilterExpiredDate: null
  }),
  beforeMount() {
    this.$store.dispatch('complexesStore/GET_ANALYTICS');
    this.getCertificatesFilterDate();
  },
  computed: {
    ...mapGetters({
      analyticsData: 'complexesStore/ANALYTICS',
    })
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getCertificatesFilterDate() {
      let startDate = new Date();
      let endDateMoment = moment(startDate).add(1, 'months');
      let endDate = endDateMoment.toDate();
      let month = endDate.getMonth() + 1;
      if (month < 10) month = '0' + month;

      this.certificatesFilterExpiredDate = endDate.getFullYear() + '-' + month + '-' + endDate.getDate();
    }
  }
};
</script>
