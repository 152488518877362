<template>
  <div>
    <apexchart
        ref="appApexChart"
        :height="height"
        :width="width"
        type="bar"
        :options="options"
        :series="series"
    />
  </div>
</template>

<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    height: {
      default: 350
    },
    width: {
      default: '100%'
    }
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$emit('markerClicked', config);
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return parseInt(val)
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return parseInt(val)
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          position: 'top',
          horizontalAlign: 'center',
          offsetX: 0,
          onItemClick: {
            toggleDataSeries: false
          },
        }
      }
    }
  },
  watch: {
    categories(data) {
      this.$refs.appApexChart.updateOptions({xaxis: {categories: data}});
    }
  }
}
</script>